import { useState, useEffect } from "react";
import { IconButton, Button, FormControl, FormControlLabel, FormGroup, Switch, InputLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { makeDeleteRequest, makeGetRequest, makePostRequest } from "../common/helpers";
import { useAlertDialog } from "./dialog/AlertDialogProvider";
import { sxStyles } from "../common/styles";
import RefreshIcon from '@mui/icons-material/Refresh';
import LoginIcon from '@mui/icons-material/Login';
import DeleteIcon from '@mui/icons-material/Delete';

interface GameCreateJoinProps {
    playerId: string,
    gameId: React.MutableRefObject<string>,
    connectToGameRoom: (gameId: string, isNewGame: boolean) => void
}

interface GameIdsResponse {
    gameIds: string[]
}

const GameCreateJoin: React.FC<GameCreateJoinProps> = ({playerId, gameId, connectToGameRoom }) => {
    const {showAlert} = useAlertDialog();
    const [gameIdsList, setGameIdsList] = useState<string[]>([]);
    const [playerCount, setPlayerCount] = useState<number>(4);
    const [lockedTeams, setLockedTeams] = useState(false);

    useEffect(() => {
        getGameIds();
      }, []);

    function setGameIdAndJoinRoom(gameIdToJoin: string) {
        gameId.current = gameIdToJoin;
        connectToGameRoom(gameId.current, false);
    }

    function joinGame(gameId: string) {
        makePostRequest("game/" + gameId + "/join", { "playerId": playerId }, (response: string) => {
            setGameIdAndJoinRoom(gameId);
        }, showAlert, shouldAlertJoinError, createRejoinFunction(gameId));
    }

    function shouldAlertJoinError(message: string) {
        return message != "You are already in this game";
    }

    function createRejoinFunction(gameId: string) {
        return function(message: string) {
            if (message == "You are already in this game") {
                // user already in the game, just rejoin
                setGameIdAndJoinRoom(gameId)
            }
        };
    }

    function createGame() {
        makePostRequest("game/", { "creatorPlayerId": playerId, "playerCount": playerCount, "lockedTeams": lockedTeams }, (response: string) => {
            setGameIdAndJoinRoom(response);
        }, showAlert);
    }

    function deleteGame(gameId: string) {
        makeDeleteRequest("game/" + gameId, (response: any) => {
            console.log(response);
            getGameIds();
        }, showAlert);
    }
    
    function getGameIds() {
        makeGetRequest("game/getAll", (response : GameIdsResponse) => {
            setGameIdsList(response.gameIds)
        }, showAlert);
    }

    function renderActiveGames(): JSX.Element {
        if (gameIdsList.length == 0) {
            return (
                <h2>No active games at the moment. Please create a new game or refresh.</h2>
            );
        }
    
        return (
            <div>
                {gameIdsList.map((gameId) => {
                    return (
                        <div style={{margin: "0px"}}>
                            {gameId}
                            <IconButton
                                style={{margin: "0px 5px"}}
                                sx={sxStyles.iconButton}
                                color="inherit"
                                onClick= {() => joinGame(gameId)}
                                size="large"
                            >
                                <LoginIcon/>
                            </IconButton>
                            <IconButton
                                style={{margin: "0px"}}
                                sx={sxStyles.iconButton}
                                color="inherit"
                                onClick= {() => deleteGame(gameId)}
                                size="large"
                            >
                                <DeleteIcon/>
                            </IconButton>
                        </div>
                    );
                })}
            </div>
        );

    }

    function playerCountChanged(e: SelectChangeEvent<number>, _: React.ReactNode) {
        setPlayerCount(e.target.value as number);
    }

    function onLockedTeamsToggled(_: React.ChangeEvent<HTMLInputElement>, checked: boolean) {
        setLockedTeams(checked);
    }

    return (
        <div>
        <div style={{width: "20%", marginLeft: "auto", marginRight: "auto"}}>
        <FormControl fullWidth style={{marginBottom: "10px"}}>
            <InputLabel>Player Count</InputLabel>
            <Select
                value={playerCount}
                label="Player Count"
                onChange={playerCountChanged}
            >
                <MenuItem value={2}>2</MenuItem>
                <MenuItem value={3}>3</MenuItem>
                <MenuItem value={4}>4</MenuItem>
                <MenuItem value={5}>5</MenuItem>
            </Select>
        </FormControl>
        </div>
        <Button style={{backgroundColor: "#7B4157", margin: "10px"}} onClick= {createGame} variant="contained">Create Game</Button>
        <FormGroup sx={{alignContent: "center"}}>
            <FormControlLabel control={<Switch checked={lockedTeams} onChange={onLockedTeamsToggled}/>} label="Locked Teams" />
        </FormGroup>
        <br></br>
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
            <h2>Active Games</h2>
            <IconButton
                style={{margin: "10px 5px"}}
                sx={sxStyles.iconButton}
                color="inherit"
                onClick= {getGameIds}
            >
                <RefreshIcon/>
            </IconButton>
        </div>
        {renderActiveGames()}
      </div>
    )
}

export default GameCreateJoin;